import "./App.css";
import { PrimeReactProvider } from "primereact/api";
import React, { createContext, useState } from "react";
import "primereact/resources/themes/lara-light-cyan/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import "primeicons/primeicons.css";
import Case from "./MedicalCases/Case";
import { RouterProvider, createBrowserRouter} from 'react-router-dom';
import AdvanceListing from "./Advances/AdvanceListing";
import MedicalCenterAdvances from "./Advances/MedicalCenterAdvances";
import CreateAdvance from "./Advances/CreateAdvance";
import MedicalCentersListing from "./MedicalCenters/MedicalCentersListing";
import RequirementsListing from "./Requirements/RequirementsListing";
import CaseDetail from "./MedicalCases/CaseDetail";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import InvoiceList from "./Invoices/InvoiceList";
import CreateInvoice from "./Invoices/CreateInvoice";
import CreateMedicalCenter from "./MedicalCenters/CreateMedicalCenter";
import Login from "./Login/Login";
import Users from "./ManageUsers/Users";
import MedicalCasesListing from "./MedicalCases/MedicalCasesListing";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Login />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/usuarios",
    element: <Users />,
  },
  {
    path: "/casos",
    element: <MedicalCasesListing />,
  },
  {
    path: "/casos/nuevo",
    element: <Case/>,
  },
  {
    path: "/casos/:caseId",
    element: <CaseDetail />,
  },
  {
    path: "/anticipos",
    element: <AdvanceListing />
  },
  {
    path: "/anticipos/nuevo",
    element: <CreateAdvance />,
  },
  {
    path: "/anticipos/proveedores/:centerId",
    element: <MedicalCenterAdvances />,
  },
  {
    path: "/proveedores",
    element: <MedicalCentersListing />,
  },
  {
    path: "/proveedores/nuevo",
    element: <CreateMedicalCenter />,
  },
  {
    path: "/requerimientos",
    element: <RequirementsListing />,
  },
  {
    path: "/centro-medico/facturas",
    element: <InvoiceList />,
  },
  {
    path: "/centro-medico/facturas/nueva",
    element: <CreateInvoice />,
  },
]);

export const AuthContext = createContext({
  isAuthenticated: false,
  userType: null,
});

export const queryClient = new QueryClient();

function App() {
  // Contexto de autenticación para mostrar el dashboard adecuado
  const [authObject, setAuthObject] = useState({
    isAuthenticated: true,
    userType: null,
  });

  return (
    <PrimeReactProvider>
      <QueryClientProvider client={queryClient}>
        <AuthContext.Provider value={[authObject, setAuthObject]}>
          <RouterProvider router={router} />
        </AuthContext.Provider>
      </QueryClientProvider>
    </PrimeReactProvider>
  );
}

export default App;
