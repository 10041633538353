// Servicios para casos médicos

import { BASE_URL } from "../constants";
import { addPerson, getPeopleMatchingCI } from "./people";

/**
 * Returns list of medical cases, filtered by medicalCenterId
 * @param {number | undefined} medicalCenterId
 */
export async function getCases(medicalCenterId) {
  const response = await fetch(BASE_URL + "medical-case/");
  if (!response.ok) {
    throw new Error(JSON.stringify(response));
  }
  const data = await response.json();
  return data;
}

/**
 * Get case detail from ID
 * @param {number} caseId 
 */
export async function getCaseById(caseId) {
  const cases = await getCases();
  return cases.find((c) => c.id === caseId);
}

/**
 * Add new medical case
 */
export async function addCase(data) {
  try {
    // Set holder CI if provided by patient
    if (data.isHolder) {
      data.ciHolder = data.ciPatient;
      data.holderCiType = data.patientCiType;
    }

    // Create patient if necessary
    if (data.shouldCreatePatient) {
      if (data.month < 10) {
        data.month = "0" + data.month;
      }
      if (data.day < 10) {
        data.day = "0" + data.day;
      }
      const patientData = {
        ci: data.patientCiType + data.ciPatient,
        name: data.patientName,
        sex: data.sex,
        birthdate: `${data.year}-${data.month}-${data.day}`,
        phone: data.phoneCode + data.phoneNumber,
        subsidiary: data.subsidiary,
        is_holder: data.isHolder,
        holder: null,
      };
      const patientResponse = await addPerson(patientData);
      if (patientResponse.type === "error") {
        return patientResponse;
      }
    }

    // Find person with matching CI
    const patientId = (await getPeopleMatchingCI(data.holderCiType + data.ciHolder))[0].id;

    // Add case
    const caseData = {
      patient: patientId,
      holder: patientId,
      diagnosis: data.diagnosis,
    };
    const fetchCalls = await Promise.all(data
      .requirements
      .map((requirement) => {
        return fetch(BASE_URL + "medical-case/", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ ...caseData, requirement: requirement.requirementMedicalCenter }),
        });
      }));
    // Check for errors
    fetchCalls.forEach(async (fetchCall) => {
      if (!fetchCall.ok) {
        throw new Error(JSON.stringify(fetchCall));
      }
    });
    return { type: "success", message: "Caso agregado correctamente." };
  } catch (error) {
    console.error("Error adding case: ", error);
    return { type: "error", message: error.message };
  }
}
