import React from "react";
import { Badge } from "primereact/badge";
import { Avatar } from "primereact/avatar";
import { Panel } from "primereact/panel";
import "./Dashboard.css";
import TopBar from "../TopBar/TopBar";
import { Menu } from "primereact/menu";
import { Link } from "react-router-dom";

// Rutas disponibles del dashboard con sus permisos
const items = [
  {
    label: "Casos Médicos",
    items: [
      { 
        label: "Crear caso",
        icon: "pi pi-plus",
        url: "/casos/nuevo",
        user: ["conciliator", "analyst", "manager"],
      },
      {
        label: "Listado de casos",
        icon: "pi pi-list-check",
        url: "/casos",
        user: ["conciliator", "analyst", "manager"],
      },
      {
        label: "Requerimientos médicos",
        icon: "pi pi-file-edit",
        url: "/requerimientos",
        user: ["conciliator"],
      },
    ],
  },
  {
    label: "Anticipos",
    items: [
      {
        label: "Crear anticipo",
        icon: "pi pi-plus",
        url: "/anticipos/nuevo",
        user: ["conciliator", "auditor"],
      },
      {
        label: "Resumen de anticipos",
        icon: "pi pi-wallet",
        url: "/anticipos",
        user: ["conciliator", "auditor"],
      },
    ],
  },
  {
    label: "Proveedores",
    items: [
      {
        label: "Crear Proveedor",
        icon: "pi pi-plus",
        url: "/proveedores/nuevo",
        user: ["conciliator", "manager", "auditor"],
      },
      {
        label: "Listado de Proveedores",
        icon: "pi pi-building-columns",
        url: "/proveedores",
        user: ["conciliator", "manager", "auditor"],
      },
    ]
  },
  {
    label: "Facturas",
    items: [
      {
        label: "Cargar factura",
        icon: "pi pi-plus",
        url: "/centro-medico/facturas/nueva",
        user: ["medicalCenter"],
      },
      {
        label: "Listado de facturas",
        icon: "pi pi-receipt",
        url: "/centro-medico/facturas",
        user: ["medicalCenter"],
      },
    ],
  },
  {
    label: "Usuarios",
    items: [
      {
        label: "Listado de usuarios",
        icon: "pi pi-users",
        url: "/usuarios",
        user: ["conciliator"],
      },
    ],
  }
];

export default function Dashboard({ headerTitle, backButtonUrl, children }) {
  return (
    <div className="layout-dashboard">
      <TopBar></TopBar>
      <div className="layout-content">
        <div className="layout-sidebar">
          <div className="mb-4">
            <Avatar label="LM" size="xlarge" shape="circle" />
            <Badge value="8" severity="info" />
          </div>
          <Menu model={items} />
        </div>
        <div className="layout-main-container">
          <div className="layout-main">
            {backButtonUrl && 
              <Link to={backButtonUrl} className="back-link"><i className="pi pi-chevron-left" aria-hidden="true"></i><span>Volver</span></Link>
            }
            <Panel header={headerTitle ?? "Dashboard"}>
              {children}
            </Panel>
          </div>
        </div>
      </div>
    </div>
  );
}
