// Servicios de facturas

const invoices = [
  {
    id: 2,
    invoiceDate: "01-09-2024",
    refNum: "123",
    amount: 200,
  },
  {
    id: 1,
    invoiceDate: "01-08-2024",
    refNum: "456",
    amount: 300,
  },
];

/**
 * Get invoices from medical center, ordered by latest
 * @param {number | string} centerId
 */
export async function getInvoicesFromMedicalCenter(centerId) {
  try {
    return invoices;
  } catch (error) {
    console.error("Error getting invoices: ", error);
    return [];
  }
}
