// Services for requirements

import { BASE_URL } from "../constants";

/**
 * Get list of requirements
 * @param {number | undefined} typeId Requirement type ID
 */
export async function getRequirements(typeId) {
  try {
    let query = typeId;
    if (!query) {
      query = "";
    }
    const response = await fetch(BASE_URL + "requirement/?type_requirement=" + query);
    if (!response.ok) {
      throw new Error(JSON.stringify(response));
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching requirements: ", error);
    return [];
  }
}

/**
 * Get medical centers that can attend a requirement
 * @param {number | undefined} requirementId 
 */
export async function getRequirementMedicalCenters(requirementId) {
  try {
    const response = await fetch(BASE_URL + "requirement-medical-center/?requirement=" + requirementId);
    if (!response.ok) {
      throw new Error(JSON.stringify(response));
    }
    const data = await response.json();
    return data.results;
  } catch (error) {
    console.error("Error fetching medical centers for requirement: ", error);
    return [];
  }
}

/**
 * Get list of requirement types
 */
export async function getRequirementTypes() {
  try {
    const response = await fetch(BASE_URL + "type-requiremet/");
    if (!response.ok) {
      throw new Error(JSON.stringify(response));
    }
    const data = await response.json();
    return data.results;
  } catch (error) {
    console.error("Error fetching requirement types: ", error);
    return [];
  }
}

/**
 * Get list of diagnosis
 */
export async function getDiagnosisList() {
  try {
    const response = await fetch(BASE_URL + "medical-diagnosis/");
    if (!response.ok) {
      throw new Error(JSON.stringify(response));
    }
    const data = await response.json();
    return data.results;
  } catch (error) {
    console.error("Error fetching diagnosis list: ", error);
    return [];
  }
}

/**
 * Add a new requirement
 * @param {object} requirement 
 * @param {number} requirement.requirementType Requirement type ID
 * @param {string} requirement.name Requirement name
 */
export async function addRequirement({ requirementType, name }) {
  try {
    const response = await fetch(BASE_URL + "requirement/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        type_requirement: requirementType,
        name: name,
      }),
    });
    if (response.status !== 201) {
      throw new Error(JSON.stringify(response));
    }
    return { type: "success", message: "Requerimiento agregado correctamente" };
  } catch (error) {
    console.error("Error adding requirement: ", error);
    return { type: "error", message: "Error al agregar el requerimiento" };
  }
}
