// Services for people (holders and patients)

import { BASE_URL } from "../constants";

/**
 * Create a person
 */
export async function addPerson(data) {
  try {
    // Add person
    const response = await fetch(BASE_URL + "person/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    if (!response.ok) {
      throw new Error(JSON.stringify(response));
    }
    return { type: "success", message: "Persona agregada correctamente." };
  } catch (error) {
    console.error("Error adding person: ", error);
    return { type: "error", message: error.message };
  }
}

/**
 * Gets list of people matching CI from left to right
 * @param {string | undefined} ci 
 */
export async function getPeopleMatchingCI(ci) {
  try {
    let query = ci;
    if (!query) {
      query = "";
    }
    const response = await fetch(BASE_URL + "/person/?ci__istartswith=" + query);
    if (!response.ok) {
      throw new Error(JSON.stringify(response));
    }
    const data = await response.json();
    return data.results ?? [];
  } catch (error) {
    console.error("Error fetching person: ", error);
    return { type: "error", message: error.message };
  }
}
