import { DataTable } from "primereact/datatable";
import Dashboard from "../Layout/Dashboard";
import { Column } from "primereact/column";
import { useQuery } from "@tanstack/react-query";
import { getInvoicesFromMedicalCenter } from "../services/invoices";
import { currencyFormat } from "../utils";
import { useState } from "react";
import { FilterMatchMode } from "primereact/api";
import { Button } from "primereact/button";

/**
 * List of invoices for a medical center
 */
export default function InvoiceList() {

  // Table filters
  const [filters] = useState({
    refNum: { value: null, matchMode: FilterMatchMode.CONTAINS },
    invoiceDate: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  // Get invoice list
  const query = useQuery({
    queryKey: ["invoices"],
    queryFn: () => getInvoicesFromMedicalCenter(1),
  });

  return (
    <Dashboard headerTitle="Lista de Facturas">
      <div className="p-fluid">
        <DataTable
          paginator
          rows={10}
          alwaysShowPaginator={false}
          emptyMessage="No se hallaron facturas."
          filters={filters}
          filterDisplay="row"
          stripedRows
          removableSort
          loading={query.isLoading}
          value={query.data ?? []}
        >
          <Column
            field="invoiceDate"
            header="Fecha"
            sortable
            filter
            showFilterMenu={false}
            filterPlaceholder="Buscar fecha"
          />
          <Column
            field="refNum"
            header="Nro. de Referencia"
            filter
            showFilterMenu={false}
            filterPlaceholder="Buscar referencia"
          />
          <Column
            field="amount"
            header="Monto"
            sortable
            body={(row) => `$${currencyFormat(row.amount)}`}
          />
          <Column
            header="Acción"
            body={(_row) => <Button icon="pi pi-eye" label="Ver" type="button" />}
          />
        </DataTable>
      </div>
    </Dashboard>
  );
}
