import { InputText } from "primereact/inputtext";
import FormSubHeading from "../components/FormSubHeading";
import Dashboard from "../Layout/Dashboard";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useState } from "react";
import { Dropdown } from "primereact/dropdown";
import { useQuery } from "@tanstack/react-query";
import { getRequirements } from "../services/requirements";
import { Button } from "primereact/button";
import "./MedicalCenters.css";
import { DeleteButton } from "../components/TableButtons";
import { InputNumber } from "primereact/inputnumber";
import { Dialog } from "primereact/dialog";
import { DAYS } from "../constants";
import { currencyFormat } from "../utils";
import VSieteCurrencyInput from "../components/VSieteCurrencyInput";

/**
 * Modal to add a new requirement to medical center
 * @param {object} props
 * @param {boolean} props.show
 * @param {() => void} props.onHide
 * @param {() => void} props.saveData
 * @param {object} props.requirementsQuery
 * @param {(requirement: any) => boolean} props.requirementsFilter Function used to exclude already used requirements
 */
function RequirementModal({ show, onHide, saveData, requirementsQuery, requirementsFilter }) {

  const [formState, setFormState] = useState({
    requirement: null,
    price: "",
    days: [],
  });

  const canSave = formState.requirement && formState.price && formState.days.every((day) => day.slots && day.slots > 0);

  /**
   * Handles clicking day button
   * @param {number} id 
   * @param {string} fullLabel
   * @param {string} shortLabel
   */
  const onDayClick = (id, fullLabel, shortLabel) => {
    // If day is already selected, remove from formstate and make button gray
    if (formState.days.find((day) => day.id === id)) {
      setFormState({
        ...formState,
        days: formState.days.filter((day) => day.id !== id),
      });
    } else {
      // Add day to form state
      setFormState({
        ...formState,
        days: [
          ...formState.days,
          { id, fullLabel, shortLabel, slots: "" }
        ],
      });
    }
  }

  /**
   * Input for daily slots
   * @param { typeof DAYS[number] & { slots: number }} row 
   */
  const dailySlotsInputBodyTemplate = (row) => (
    <InputNumber
      placeholder="Ingrese los cupos para este día"
      value={formState.days.find((day) => day.id === row.id)?.slots}
      onChange={(e) => {
        const newDays = formState.days.map((day) => {
          if (day.id === row.id) return { ...day, slots: e.value};
          return day;
        });
        setFormState({ ...formState, days: newDays });
      }}
    />
  );

  return (
    <Dialog visible={show} onHide={onHide} draggable={false} header="Nuevo requerimiento">
      <div className="p-fluid">
        <FormSubHeading title="Requerimiento" />
        <div className="p-field">
          <label htmlFor="requirement">Requerimiento</label>
          <Dropdown
            value={formState.requirement}
            onChange={(e) => setFormState({ ...formState, requirement: e.value })}
            loading={requirementsQuery.isLoading}
            disabled={requirementsQuery.isLoading}
            options={requirementsQuery.data?.filter(requirementsFilter) ?? []}
            optionValue="id"
            optionLabel="name"
            placeholder="Seleccionar requerimiento"
            filter
          />
        </div>
        <div className="p-field">
          <label htmlFor="price">Precio</label>
          <VSieteCurrencyInput
            id="price"
            name="price"
            prefix="$"
            onValueChange={(_value, _name, values) => setFormState({ ...formState, price: values.float })}
          />
        </div>
        <FormSubHeading title="Disponibilidad" />
        <div className="mb-3">
          <span>Seleccione los días de atención:</span>
          <div className="day-row-selector my-2">
            { DAYS.map((day) => (
              <Button
                key={day.id}
                label={day.shortLabel}
                aria-label={day.fullLabel}
                onClick={() => onDayClick(day.id, day.fullLabel, day.shortLabel)}
                className={formState.days.find((formDays) => formDays.id === day.id) ? "" : "btn-off"}
              />
            )) }
          </div>
          {!!formState.days.length && (
            <DataTable value={formState.days} dataKey="id">
              <Column header="Día" field="fullLabel" />
              <Column header="Cupos disponibles" body={dailySlotsInputBodyTemplate}  />
            </DataTable>
          )}
        </div>
        <Button
          type="button"
          onClick={() => {
            saveData(formState);
            setFormState({
              requirement: null,
              price: "",
              days: [],
            });
          }}
          label="Guardar"
          disabled={!canSave}
        />
      </div>
    </Dialog>
  );
}

/**
 * Form to create new medical centers
 */
export default function CreateMedicalCenter() {

  // State for form
  const [name, setName] = useState("");
  const [slots, setSlots] = useState([]);

  // For modal
  const [showModal, setShowModal] = useState(false);

  // Get available requirements
  const requirementsQuery = useQuery({
    queryKey: ["requirements"],
    queryFn: () => getRequirements(),
  });

  /**
   * Requirement template for table body
   * @returns {string}
   */
  const requirementBodyTemplate = (row) => (
    requirementsQuery.data?.find((req) => req.id === row.requirement)?.name
  );

  /**
   * Input for requirement price at medical center
   */
  const priceBodyTemplate = (row) => `$${currencyFormat(row.price)}`;

  /**
   * Returns days attended
   */
  const daysAttendedTemplate = (row) => (
    row.days.map((day) => day.fullLabel).join(",")
  );

  /**
   * Delete button for rows
   * @param {typeof slots[number]} row 
   */
  const actionBodyTemplate = (row) => (
    <DeleteButton
      onClick={() => {
        const newSlots = slots.filter(slot => slot.requirement !== row.requirement);
        setSlots(newSlots);
      }}
    />
  );

  const canSave = name !== "" && 
    slots.every((slot) => slot.requirement && slot.price && slot.days.every((day) => day.slots && day.slots > 0));

  return (
    <Dashboard headerTitle="Nuevo Proveedor">
      <form className="p-fluid">
        <div className="pb-3">
          <FormSubHeading title="Datos del proveedor"/>
          <div className="p-field">
            <label htmlFor="name">Nombre del proveedor</label>
            <InputText
              id="name"
              name="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
              autoFocus
            />
          </div>
        </div>
        <div className="pb-3 center-requirement-config">
          <FormSubHeading title="Disponibilidad de servicios" />
          <DataTable
            stripedRows
            value={slots}
            emptyMessage="No ha registrado requerimientos para este proveedor"
            dataKey="requirement"
          >
            <Column header="Requerimiento" field="requirement" body={requirementBodyTemplate} />
            <Column header="Precio" field="price" body={priceBodyTemplate} />
            <Column header="Días atendido" field="days" body={daysAttendedTemplate} />
            <Column header="Acción" body={actionBodyTemplate} />
          </DataTable>
          <RequirementModal
            show={showModal}
            onHide={() => { if (showModal) setShowModal(false); }}
            saveData={(slot) => setSlots([...slots, slot])}
            requirementsQuery={requirementsQuery}
            requirementsFilter={(req) => !slots.find((slot) => slot.requirement === req.id)}
          />
          <div id="btn-container">
            <Button
              label="Agregar requerimiento"
              icon="pi pi-plus"
              onClick={() => setShowModal(true)}
            />
            <Button
              label="Guardar proveedor"
              icon="pi pi-save"
              severity="success"
              type="button"
              disabled={!canSave}
            />
          </div>
        </div>
      </form>
    </Dashboard>
  );
}
