import "../App.css";

/**
 * Encabezado secundario para formulario
 * @param {object} props
 * @param {string} props.title 
 */
export default function FormSubHeading({ title }) {
  return (
    <div className="form-sub-heading">
      <span></span>
      <div>
        <h3>{title}</h3>
      </div>
    </div>
  )
}
