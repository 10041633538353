import { Button } from "primereact/button";

/**
 * Edit button for tables
 * @param {object} props
 * @param {() => void} props.onClick
 */
export function EditButton({ onClick }) {
  return (
    <Button
      icon="pi pi-pencil"
      className="p-button-rounded mr-2"
      style={{ backgroundColor: "#fbbf24", borderColor: "#fbbf24" }}
      tooltip="Editar"
      tooltipOptions={{ position: "top" }}
      onClick={onClick}
    />
  );
}

/**
 * Delete button for tables
 * @param {object} props
 * @param {() => void} props.onClick
 */
export function DeleteButton({ onClick }) {
  return (
    <Button
      icon="pi pi-trash"
      className="p-button-rounded"
      severity="danger"
      tooltip="Eliminar"
      tooltipOptions={{ position: "top" }}
      onClick={onClick}
    />
  );
}
