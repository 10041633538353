// FormComponent.js
import React, { useEffect, useRef, useState } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import FormSubHeading from "../components/FormSubHeading";
import "./CreateCase.css";
import { Dialog } from "primereact/dialog";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { MultiSelect } from "primereact/multiselect";
import { useMutation, useQuery } from "@tanstack/react-query";
import { getDiagnosisList, getRequirementMedicalCenters, getRequirements, getRequirementTypes } from "../services/requirements";
import { getMedicalCenters } from "../services/medicalCenters";
import { DeleteButton, EditButton } from "../components/TableButtons";
import { DAYS, GENDERS, MONTHS, PHONE_CODES } from "../constants";
import { addCase } from "../services/medicalCases";
import { queryClient } from "../App";
import { getPeopleMatchingCI } from "../services/people";
import { SelectButton } from "primereact/selectbutton";
import { Toast } from "primereact/toast";
import InputWithSuggestions from "../components/InputWithSuggestions";

/**
 * Modal para registrar un requerimiento
 * @param {object} props
 * @param {boolean} props.showModal
 * @param {() => void} props.onHide
 * @param {(any) => void} props.setRequirementList
 * @param {(boolean) => void} props.setInputsLocked
 */
function RequirementModal({ showModal, onHide, setRequirementList, setInputsLocked, initialValues }) {

  const [requirementType, setRequirementType] = useState(1);
  const [requirement, setRequirement] = useState(null);
  const [medicalCenterLabel, setMedicalCenterLabel] = useState("");
  const [requirementMedicalCenter, setRequirementMedicalCenter] = useState(null);

  useEffect(() => {
    if (initialValues) {
      setRequirementType(initialValues.requirementType);
      setRequirement([initialValues.requirement]);
    }
  }, [initialValues]);

  const canSave = requirementType !== null && requirement !== null && requirementMedicalCenter !== null;

  // Do necessary queries
  const requirementTypesQuery = useQuery({
    queryKey: ["requirementTypes"],
    queryFn: () => getRequirementTypes(),
  });
  const requirementsQuery = useQuery({
    queryKey: ["requirements", requirementType],
    queryFn: () => getRequirements(requirementType),
  });
  const medicalCentersQuery = useQuery({
    queryKey: ["medicalCenters"],
    queryFn: () => getMedicalCenters(),
  });
  const requirementMedicalCentersQuery = useQuery({
    queryKey: ["reqMedicalCenters", requirement],
    queryFn: () => getRequirementMedicalCenters(requirement[0]),
  });

  const centers = requirementMedicalCentersQuery
    .data
    ?.map((center) => ({
      requirementMedId: center.id,
      centerLabel: center.medical_center_name,
    }))
    .filter((center) => {
      const providerFound = medicalCentersQuery.data?.find((prov) => prov.name === center.centerLabel);
      return providerFound && providerFound.consumed < 85;
    }) ?? [];

  function saveRequirement() {
    // Edit requirement
    if (initialValues) {
      setRequirementList((prev) => {
        const newList = prev.map((req) => {
          // If has found requirement to edit
          if (req === initialValues) {
            return {
              // Requirement type
              requirementType,
              requirementTypeLabel: requirementTypesQuery.data.find((type) => type.id === requirementType).name,
              // Requirement
              requirement: requirement[0],
              requirementLabel: requirementsQuery.data.find((r) => r.id === requirement[0]).name,
              // Requirement medical center
              requirementMedicalCenter,
              requirementId: requirementMedicalCenter,
              medicalCenterLabel
            };
          }
          return req;
        });
        return newList;
      });
    } else {
      // Add new requirement
      setRequirementList((prev) => {
        const newList = [
          ...prev,
          ...requirement.map((req) => ({
            // Requirement type
            requirementType,
            requirementTypeLabel: requirementTypesQuery.data.find((type) => type.id === requirementType).name,
            // Requirement
            requirement: requirement[0],
            requirementLabel: requirementsQuery.data.find((r) => r.id === req).name,
            // Requirement medical center
            requirementMedicalCenter,
            requirementId: requirementMedicalCenter,
            medicalCenterLabel,
          })),
        ];
        return newList;
      });
    }
    setRequirementType(1);
    setRequirement(null);
    setInputsLocked("all");
    onHide();
  }

  return (
    <Dialog
      header="Nuevo Requerimiento"
      visible={showModal}
      onHide={onHide}
      draggable={false}
      style={{ minWidth: "30vw" }}
      contentClassName="p-fluid"
    >
      <FormSubHeading title="Requerimiento médico" />
      <div className="p-field">
        <label htmlFor="requirementType">Tipo de requerimiento</label>
        <Dropdown
          inputId="requirementType"
          name="requirementType"
          value={requirementType}
          onChange={(e) => {
            setRequirementType(e.value);
            setRequirement(null);
          }}
          options={requirementTypesQuery.data ?? []}
          optionValue="id"
          optionLabel="name"
          loading={requirementTypesQuery.isLoading}
          disabled={requirementTypesQuery.isLoading}
          placeholder="Seleccionar tipo"
          filter
        />
      </div>
      <div className="p-field">
        <label htmlFor="requirement">Requerimiento</label>
        <MultiSelect
          inputId="requirement"
          name="requirement"
          disabled={requirementType === null || requirementsQuery.isLoading}
          value={requirement}
          onChange={(e) => setRequirement(e.value)}
          options={requirementsQuery.data ?? []}
          optionValue="id"
          optionLabel="name"
          loading={requirementsQuery.isLoading}
          placeholder="Seleccionar requerimiento"
          filter
        />
      </div>
      <FormSubHeading title="Ubicación" />
      <div className="p-field">
        <label htmlFor="medicalCenter">Centro médico</label>
        <Dropdown
          inputId="medicalCenter"
          name="medicalCenter"
          disabled={requirement === null || medicalCentersQuery.isLoading}
          value={requirementMedicalCenter}
          onChange={(e) => {
            console.log(e);
            setRequirementMedicalCenter(e.value);
            setMedicalCenterLabel(centers.find((center) => center.requirementMedId === e.value).centerLabel);
          }}
          options={centers ?? []}
          optionValue="requirementMedId"
          optionLabel="centerLabel"
          loading={medicalCentersQuery.isLoading}
          placeholder="Seleccionar centro médico"
          filter
        />
      </div>
      <div className="p-field">
        <span>Día de atención</span>
        <div className="case-date-selector my-2">
          {DAYS.map((day) => (
            <Button label={`${day.fullLabel} (3 cupos disponibles)`} />
          ))}
        </div>
      </div>
      <Button label="Guardar" onClick={saveRequirement} disabled={!canSave} />
    </Dialog>
  )
}

/**
 * Tabla de requerimientos
 */
function RequirementsTable({ requirements, setRequirementList, setShowRequirementModal, setEditValues }) {

  function deleteRequirement(requirement) {
    setRequirementList((prev) => prev.filter((req) => req !== requirement));
  }

  /**
   * Show action buttons
   */
  const actionBody = (row) => (
    <div className="flex">
      <EditButton onClick={() => {
        setEditValues(row)
        setShowRequirementModal(true);
      }} />
      <DeleteButton onClick={() => deleteRequirement(row)} />
    </div>
  );

  return (
    <DataTable value={requirements} className="mt-5" style={{ maxWidth: "40vw" }}>
      <Column field="requirementTypeLabel" header="Tipo de requerimiento" />
      <Column field="requirementLabel" header="Requerimiento" />
      <Column field="medicalCenterLabel" header="Centro médico" />
      <Column header="Acciones" body={actionBody} />
    </DataTable>
  );
}

/**
 * Formulario para crear un nuevo caso médico
 */
const FormComponent = () => {

  // Form state
  const [formState, setFormState] = useState({
    patientCiType: "V",
    ciPatient: "",
    patientName: "",
    sex: null,
    day: null,
    month: null,
    year: null,
    phoneCode: "58412",
    phoneNumber: "",
    diagnosis: null,
    subsidiary: null,
    isHolder: true,
    holderCiType: "V",
    ciHolder: "",
    shouldCreatePatient: true,
  });
  const [inputsLocked, setInputsLocked] = useState("");

  // Birth date state
  const numDays = formState.year && formState.month ? new Date(formState.year, formState.month, 0).getDate() : 31;

  // Requirements
  const [showRequirementModal, setShowRequirementModal] = useState(false);
  const [requirementList, setRequirementList] = useState([]);
  const [editValues, setEditValues] = useState(null);

  // Get diagnosis list
  const diagnosisQuery = useQuery({
    queryKey: ["diagnosisList"],
    queryFn: () => getDiagnosisList(),
  });

  // Get patient by CI
  const patientQuery = useQuery({
    queryKey: ["patient", formState.patientCiType + formState.ciPatient],
    queryFn: () => getPeopleMatchingCI(formState.patientCiType + formState.ciPatient),
    enabled: formState.ciPatient.length >= 3,
  });

  // Patient CI search item template
  const patientItemTemplate = (item) => {
    return (
      <span className="py-4" onClick={() => selectPatient(item)}>{item.ci} - {item.name}</span>
    );
  }

  // Mutation for new requirement
  const requirementMutation = useMutation({
    mutationFn: () => addCase({ ...formState, requirements: requirementList }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["medicalCases"],
      });
      toast.current.show({ severity: "success", summary: "Éxito", detail: "Caso médico registrado" });
      setFormState({
        patientCiType: "V",
        ciPatient: "",
        patientName: "",
        sex: null,
        day: null,
        month: null,
        year: null,
        phoneCode: "58412",
        phoneNumber: "",
        diagnosis: null,
        subsidiary: null,
        isHolder: true,
        holderCiType: "V",
        ciHolder: "",
        shouldCreatePatient: true,
      });
      setRequirementList([]);
      patientSearchRef.current.hide();
    }
  });

  const canAddRequirement = Object
    .keys(formState)
    .every((key) => {
      if (key === "ciHolder" && formState.isHolder) return true;
      return formState[key] !== null && formState !== "";
    });

  const canSaveCase = canAddRequirement && requirementList.length;

  // Form message toast
  const toast = useRef(null);

  // Patient search overlay
  const patientSearchRef = useRef(null);

  /**
   * Key up event for patient search
   * @param {KeyboardEvent} e 
   */
  const onPatientSearchEnter = (e) => {
    if (e.key === "Escape") {
      patientSearchRef.current.hide();
    }
    if (e.key === "Enter") {
      if (!patientQuery.data || !patientQuery.data.length) {
        setFormState({ ...formState, shouldCreatePatient: true });
        patientSearchRef.current.hide();
      } else {
        selectPatient(patientQuery.data[0]);
      }
    }
  }

  /**
   * Sets form state from a selected patient
   * @param {object} patient
   * @param {number} patient.id
   * @param {string} patient.ci
   * @param {string} patient.name
   * @param {"M" | "F"} patient.sex
   * @param {string} patient.birthdate
   * @param {string} patient.phone
   * @param {string} patient.subsidiary
   * @param {boolean} patient.is_holder
   * @param {string | null} patient.holder
   */
  const selectPatient = (patient) => {
    const newObj = {
      patientCiType: patient.ci.slice(0, 1),
      ciPatient: patient.ci.slice(1),
      patientName: patient.name,
      sex: patient.sex,
      day: String(Number(patient.birthdate.split("-")[2])),
      month: Number(patient.birthdate.split("-")[1]),
      year: patient.birthdate.split("-")[0],
      phoneCode: patient.phone.slice(0, 5),
      phoneNumber: patient.phone.slice(5),
      subsidiary: patient.subsidiary,
      isHolder: patient.is_holder,
      diagnosis: null,
      holderCiType: "V",
      ciHolder: "",
    };
    if (!patient.is_holder) {
      newObj.holderCiType = patient.holder.slice(0, 1);
      newObj.ciHolder = patient.holder.slice(1);
    }
    setFormState(newObj);
    setInputsLocked("some");
    // Show message
    toast.current.show({ severity: "success", summary: "Éxito", detail: "Se rellenaron los datos del paciente. Indique el diagnóstico." });
    patientSearchRef.current.hide();
    // Scroll to add diagnosis
    //diagnosisRef.current.scrollIntoView({ behavior: "smooth" });
  }

  // Diagnosis ref
  const diagnosisRef = useRef(null);

  return (
    <div className="p-fluid">
      <Toast ref={toast} position="top-center" />
      <form>
        <FormSubHeading title="Datos del Paciente" />
        <div className="p-field">
          <label htmlFor="ciPatient">Cédula</label>
          <div className="field-with-select">
            <Dropdown
              inputId="patientCiType"
              name="patientCiType"
              options={["V", "E"]}
              placeholder="Tipo"
              value={formState.patientCiType}
              onChange={(e) => setFormState({ ...formState, patientCiType: e.value })}
              required
              disabled={inputsLocked === "all"}
            />
            <InputWithSuggestions
              inputName="patientCi"
              inputValue={formState.ciPatient}
              inputDisabled={inputsLocked === "all"}
              onTextChange={(e) => setFormState({ ...formState, ciPatient: e.target.value })}
              query={patientQuery}
              notFoundBtnLabel="Crear paciente con esta cédula"
              notFoundBtnClick={() => {
                setFormState({ ...formState, shouldCreatePatient: true });
                patientSearchRef.current.hide();
              }}
              itemTemplate={patientItemTemplate}
              overlayRef={patientSearchRef}
              onSearchEnter={onPatientSearchEnter}
            />
          </div>
        </div>
        <div className="p-field">
          <label htmlFor="patientName">Nombre completo</label>
          <InputText
            id="patientName"
            name="patientName"
            value={formState.patientName}
            onChange={(e) => setFormState({ ...formState, patientName: e.target.value })}
            disabled={inputsLocked !== "" || patientQuery.isLoading}
          />
        </div>
        <div className="p-field">
          <label htmlFor="gender">Sexo</label>
          <Dropdown
            inputId="gender"
            name="gender"
            value={formState.sex}
            options={GENDERS}
            onChange={(e) => setFormState({ ...formState, sex: e.value })}
            placeholder="Seleccionar sexo"
            disabled={inputsLocked !== "" || patientQuery.isLoading}
          />
        </div>
        <div className="p-field">
          <span id="birthdate-label">Fecha de nacimiento</span>
          <div className="birthdate-container">
            <Dropdown
              ariaLabelledBy="birthdate-label"
              inputId="day"
              value={formState.day}
              options={Array.from({ length: numDays }, (_, i) => String(i + 1))}
              onChange={(e) => setFormState({ ...formState, day: e.value })}
              placeholder="Día"
              disabled={inputsLocked !== "" || patientQuery.isLoading}
              filter
            />
            <Dropdown
              ariaLabelledBy="birthdate-label"
              inputId="month"
              value={formState.month}
              options={MONTHS}
              onChange={(e) => setFormState({ ...formState, month: e.value })}
              placeholder="Mes"
              disabled={inputsLocked !== "" || patientQuery.isLoading}
              filter
            />
            <Dropdown
              ariaLabelledBy="birthdate-label"
              inputId="year"
              value={formState.year}
              options={Array.from({ length: 100 }, (_, i) => String(new Date().getFullYear() - i))}
              onChange={(e) => setFormState({ ...formState, year: e.value })}
              placeholder="Año"
              disabled={inputsLocked !== "" || patientQuery.isLoading}
              filter
            />
          </div>
        </div>
        <div className="p-field">
          <label htmlFor="phoneNumber">Número de teléfono</label>
          <div className="field-with-select">
            <Dropdown
              inputId="phoneCode"
              name="phoneCode"
              options={PHONE_CODES}
              placeholder="Código"
              value={formState.phoneCode}
              onChange={(e) => setFormState({ ...formState, phoneCode: e.value })}
              disabled={inputsLocked !== "" || patientQuery.isLoading}
            />
            <InputText
              id="phoneNumber"
              name="phoneNumber"
              value={formState.phoneNumber}
              onChange={(e) => setFormState({ ...formState, phoneNumber: e.target.value })}
              disabled={inputsLocked !== "" || patientQuery.isLoading}
            />
          </div>
        </div>
        <FormSubHeading title="Diagnóstico" />
        <div ref={diagnosisRef} className="p-field">
          <label htmlFor="diagnosis">Diagnóstico</label>
          <MultiSelect
            inputId="diagnosis"
            name="diagnosis"
            value={formState.diagnosis}
            onChange={(e) => setFormState({ ...formState, diagnosis: e.value })}
            options={diagnosisQuery.data ?? []}
            optionValue="id"
            optionLabel="name"
            placeholder="Seleccionar diagnóstico"
            filter
            loading={diagnosisQuery.isLoading}
            disabled={diagnosisQuery.isLoading || inputsLocked === "all"}
          />
        </div>
        <FormSubHeading title="Filial" />
        <div className="p-field">
          <label htmlFor="subsidiary">Filial</label>
          <Dropdown
            inputId="subsidiary"
            name="subsidiary"
            options={[
              { label: "PDVSA", value: "PDVSA" },
              { label: "Pequiven", value: "Pequiven" },
            ]}
            value={formState.subsidiary}
            onChange={(e) => setFormState({ ...formState, subsidiary: e.value })}
            placeholder="Seleccionar filial"
            filter
            disabled={inputsLocked !== "" || patientQuery.isLoading}
          />
        </div>
        <div className="is-holder-container">
          <span>¿Es titular?</span>
          <SelectButton
            value={formState.isHolder}
            onChange={(e) => setFormState({ ...formState, isHolder: e.value })}
            options={[
              { label: "Si", value: true },
              { label: "No", value: false }
            ]}
            disabled={inputsLocked !== "" || patientQuery.isLoading}
          />
        </div>
        {!formState.isHolder && (
          <div className="holder-data-container">
            <FormSubHeading title="Datos del Titular" />
            <div className="p-field">
              <label htmlFor="holderCi">Cédula</label>
              <div className="field-with-select">
                <Dropdown
                  id="holderCiType"
                  name="holderCiType"
                  options={["V", "E"]}
                  placeholder="Tipo"
                  value={formState.holderCiType}
                  onChange={(e) => setFormState({ ...formState, holderCiType: e.value })}
                  required
                  disabled={inputsLocked !== ""}
                />
                <InputText
                  id="holderCi"
                  name="holderCi"
                  value={formState.ciHolder}
                  onChange={(e) => setFormState({ ...formState, ciHolder: e.target.value })}
                  disabled={inputsLocked !== ""}
                />
              </div>
            </div>
          </div>
        )}
      </form>
      {!!requirementList.length && (
        <>
          <FormSubHeading title="Requerimientos" />
          <RequirementsTable
            requirements={requirementList}
            setRequirementList={setRequirementList}
            setShowRequirementModal={setShowRequirementModal}
            setEditValues={setEditValues}
          />
        </>
      )}
      <Button
        type="button"
        icon="pi pi-plus"
        label="Añadir requerimiento"
        className="mt-4"
        severity="info"
        disabled={!canAddRequirement}
        onClick={() => {
          setEditValues(null);
          setShowRequirementModal(true);
        }}
      />
      {!!canSaveCase && (
        <Button
          type="button"
          icon="pi pi-save"
          label="Guardar caso"
          className="mt-4"
          severity="success"
          loading={requirementMutation.isLoading}
          disabled={requirementMutation.isLoading}
          onClick={() => requirementMutation.mutate()}
        />
      )}
      <RequirementModal
        showModal={showRequirementModal}
        onHide={() => {
          if (!showRequirementModal) return;
          setEditValues(null);
          setShowRequirementModal(false);
        }}
        setRequirementList={setRequirementList}
        setInputsLocked={setInputsLocked}
        initialValues={editValues}
      />
    </div>
  );
};

export default FormComponent;
