import { DataTable } from "primereact/datatable";
import Dashboard from "../Layout/Dashboard";
import { Column } from "primereact/column";
import { useNavigate } from "react-router-dom";
import { currencyFormat } from "../utils";
import { Button } from "primereact/button";
import { useQuery } from "@tanstack/react-query";
import { getMedicalCenters } from "../services/medicalCenters";
import { useState } from "react";
import { FilterMatchMode, FilterService } from "primereact/api";
import { ProgressBar } from "primereact/progressbar";
import { ProgressSpinner } from "primereact/progressspinner";
import "./Advances.css";
import { InputNumber } from "primereact/inputnumber";

/**
 * Applies currency format to the given number for the datatable
 * @param {string} key 
 */
function createCurrencyTemplate(key) {
  return (row) => currencyFormat(row[key]);
}

/**
 * Show the consumed percentage with icon
 */
function consumedPercentageTemplate(row) {
  let color = "#16a34a";
  if (row.consumed >= 85) {
    color = "#dc2626";
  } else if (row.consumed >= 70) {
    color = "#f59e0b";
  }

  return (
    <div style={{
      display: "flex",
      flexDirection: "column",
      textAlign: "center",
      gap: "0.5rem",
    }}>
      <span>{Number(row.consumed).toFixed(2)}%</span>
      <ProgressBar
        value={row.consumed}
        showValue={false}
        style={{ maxHeight: "0.8rem" }}
        color={color}
      />
    </div>
  );
}

// Register consumed filter
FilterService.register('custom_consumed', (value, filters) => {
  const [from, to] = filters ?? [null, null];
  if (from === null && to === null) return true;
  if (from !== null && to === null) return from <= Number(value);
  if (from === null && to !== null) return Number(value) <= to;
  return from <= Number(value) && Number(value) <= to;
});

/**
 * Filter for consumed percentage
 */
const consumedFilterTemplate = (options) => {
  const [from, to] = options.value ?? [null, null];

  return (
    <div className="flex gap-1">
      <InputNumber value={from} onChange={(e) => options.filterApplyCallback([e.value, to])} className="w-full" placeholder="desde" />
      <InputNumber value={to} onChange={(e) => options.filterApplyCallback([from, e.value])} className="w-full" placeholder="hasta" />
    </div>
  );
};

/**
 * Listado de centros médicos con su anticipo
 */
export default function AdvanceListing() {

  // Table filters
  const [filters] = useState({
    name: { value: null, matchMode: FilterMatchMode.CONTAINS },
    consumed: { value: null, matchMode: FilterMatchMode.CUSTOM, },
  });

  // Get medical centers
  const providersQuery = useQuery({
    queryKey: ["providers"],
    queryFn: () => getMedicalCenters(),
  });

  // Calculate summary data
  const [totalFund, totalIncurred, totalAvailable] = providersQuery.isLoading ? 
    [null, null, null]
    : [
      providersQuery.data.reduce((prev, provider) => prev + Number(provider.total_advance), 0),
      providersQuery.data.reduce((prev, provider) => prev + Number(provider.total_incurred), 0),
      providersQuery.data.reduce((prev, provider) => prev + Number(provider.available), 0),
    ];
  const totalPercentageConsumed = Number(totalIncurred / totalFund * 100).toFixed(2);

  // Detail navigation
  const navigate = useNavigate();
  function navigateToClinic(event) {
    navigate("proveedores/" + event.data.id);
  }

  return (
    <Dashboard headerTitle="Resumen de Anticipos">
      <div className="p-2 p-fluid">
        <div className="advance-summary-container">
          <div className="p-card">
            <h3>Fondo total</h3>
            {providersQuery.isLoading ?
              <ProgressSpinner /> : <p>${currencyFormat(totalFund)}</p>
            }
          </div>
          <div className="p-card">
            <h3>Fondo incurrido</h3>
            {providersQuery.isLoading ?
              <ProgressSpinner /> : <p>${currencyFormat(totalIncurred)}</p>
            }
          </div>
          <div className="p-card">
            <h3>Fondo disponible</h3>
            {providersQuery.isLoading ?
              <ProgressSpinner /> : <p>${currencyFormat(totalAvailable)}</p>
            }
          </div>
          <div className="p-card">
            <h3>% Consumido</h3>
            {providersQuery.isLoading ?
              <ProgressSpinner />
              : <div className="mb-3">{consumedPercentageTemplate({ consumed: totalPercentageConsumed })}</div>
            }
          </div>
        </div>
        <DataTable
          value={providersQuery.data ?? []}
          stripedRows
          paginator
          alwaysShowPaginator={false}
          rows={15}
          rowsPerPageOptions={[10, 15, 25, 50]}
          selectionMode="single"
          onRowSelect={(event) => navigateToClinic(event)}
          dataKey="id"
          filters={filters}
          filterDisplay="row"
          removableSort
          emptyMessage="No se hallaron proveedores."
          loading={providersQuery.isLoading}
        >
          <Column
            field="name"
            header="Proveedor"
            sortable
            filter
            filterPlaceholder="Buscar proveedor"
            showFilterMenu={false}
          />
          <Column
            field="number_atention"
            header="Nro. de Atenciones"
            sortable
          />
          <Column
            field="total_advance"
            header="Fondo ($)"
            sortable
            body={createCurrencyTemplate('total_advance')}
          />
          <Column
            field="total_incurred"
            header="Total incurrido ($)"
            sortable
            body={createCurrencyTemplate('total_incurred')}
          />
          <Column
            field="available"
            header="Disponible ($)"
            sortable
            body={createCurrencyTemplate('available')}
          />
          <Column
            field="consumed"
            header="Consumido"
            sortable
            body={consumedPercentageTemplate}
            filter
            filterElement={consumedFilterTemplate}
            showFilterMenu={false}
          />
          <Column
            header="Acción"
            body={
              (row) => <Button icon="pi pi-eye" label="Ver anticipos" onClick={() => navigate(`proveedores/${row.id}`)} />
            }
          />
        </DataTable>
      </div>
    </Dashboard>
  )
}
