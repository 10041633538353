import { DataTable } from "primereact/datatable";
import Dashboard from "../Layout/Dashboard";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { useRef, useState } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { DeleteButton, EditButton } from "../components/TableButtons";
import { useMutation, useQuery } from "@tanstack/react-query";
import { addRequirement, getRequirements, getRequirementTypes } from "../services/requirements";
import { FilterMatchMode } from "primereact/api";
import { queryClient } from "../App";
import { Toast } from "primereact/toast";

/**
 * Modal to add a new requirement
 * @param {object} props 
 * @param {boolean} props.show
 * @param {() => void} props.onHide
 * @param {object} props.requirementTypes
 * @param {React.MutableRefObject} props.toastRef
 */
function AddRequirementModal({ show, onHide, requirementTypes, toastRef }) {

  // Form state
  const [dropdownRequirementType, setDropdownRequirementType] = useState(null);
  const [requirementName, setRequirementName] = useState("");

  // Add requirement mutation
  const addRequirementMutation = useMutation({
    mutationFn: () => addRequirement({ requirementType: dropdownRequirementType, name: requirementName }),
    onSuccess: ({ type, message }) => {
      if (type === "error") {
        toastRef.current.show({ severity: "error", summary: "Error", detail: message });
      } else {
        queryClient.invalidateQueries({
          queryKey: ["requirements"],
        });
        toastRef.current.show({ severity: "success", summary: "Requerimiento creado", detail: message });
      }
      onHide();
    }
  });

  return (
    <Dialog
      header="Nuevo requerimiento"
      visible={show}
      contentClassName="p-fluid"
      onHide={onHide}
      draggable={false}
    >
      <form>
        <div className="p-field">
          <label htmlFor="type">Tipo</label>
          <Dropdown
            id="type"
            name="type"
            value={dropdownRequirementType}
            onChange={(e) => setDropdownRequirementType(e.value)}
            options={requirementTypes.data ?? []}
            optionValue="id"
            optionLabel="name"
            loading={requirementTypes.isLoading}
            disabled={requirementTypes.isLoading}
            placeholder="Seleccionar tipo"
            required
          />
        </div>
        <div className="p-field">
          <label htmlFor="name">Nombre</label>
          <InputText
            id="name"
            name="name"
            value={requirementName}
            onChange={(e) => setRequirementName(e.target.value)}
            required
          />
        </div>
        <Button
          type="button"
          label="Guardar"
          onClick={() => addRequirementMutation.mutate()}
          loading={addRequirementMutation.isPending}
          disabled={addRequirementMutation.isPending}
        />
      </form>
    </Dialog>
  );
}

/**
 * Listado de requerimientos registrados (CRUD)
 */
export default function RequirementsListing() {

  // Modal state
  const [showRequirementModal, setShowRequirementModal] = useState("none");
  const [selectedRequirement, setSelectedRequirement] = useState(null);

  // Table filters
  const [filters] = useState({
    type_requirement: { value: null, matchMode: FilterMatchMode.EQUALS },
    name: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  // Get requirements and requirements type
  const requirementsQuery = useQuery({
    queryKey: ["requirements"],
    queryFn: () => getRequirements(),
  });
  const requirementsTypeQuery = useQuery({
    queryKey: ["requirementTypes"],
    queryFn: () => getRequirementTypes(),
  });

  /**
   * Filter dropdown for requirement type
   */
  const requirementTypeFilter = (options) => (
    <Dropdown
      value={options.value}
      options={requirementsTypeQuery.data ?? []}
      optionValue="id"
      optionLabel="name"
      loading={requirementsTypeQuery.isLoading}
      disabled={requirementsTypeQuery.isLoading}
      onChange={(e) => options.filterApplyCallback(e.value)}
      placeholder="Seleccionar tipo"
      className="p-column-filter"
      showClear
    />
  );

  const requirementTypeBody = (row) => {
    return requirementsTypeQuery.data?.find((t) => t.id === row.type_requirement)?.name;
  }

  /**
   * Show action buttons
   */
  function requirementsActionTemplate(row) {
    return (
      <div className="d-flex justify-content-center">
        <EditButton onClick={() => { setShowRequirementModal("edit"); setSelectedRequirement(row); }} />
        <DeleteButton onClick={() => { setShowRequirementModal("delete"); setSelectedRequirement(row); }} />
      </div>
    )
  }

  // Ref for toast
  const toastRef = useRef(null);

  return (
    <Dashboard headerTitle="Listado de Requerimientos">
      <Toast ref={toastRef} position="top-center" />
      <div className="p-fluid">
        <Button
          icon="pi pi-plus"
          label="Crear Requerimiento"
          className="mb-3"
          style={{ maxWidth: "fit-content" }}
          onClick={() => setShowRequirementModal("new")}
        />
        <DataTable
          value={requirementsQuery.data ?? []}
          loading={requirementsQuery.isLoading}
					stripedRows
					paginator
					alwaysShowPaginator={false}
					rows={15}
					rowsPerPageOptions={[10, 15, 25, 50]}
					dataKey="id"
          filters={filters}
					filterDisplay="row"
					removableSort
        >
          <Column
            header="Tipo"
            field="type_requirement"
            sortable
            filter
            filterPlaceholder="Buscar tipo"
            filterElement={requirementTypeFilter}
            showFilterMenu={false}
            body={requirementTypeBody}
          />
          <Column
            field="name"
            header="Nombre"
            sortable
            filter
            filterPlaceholder="Buscar nombre"
            showFilterMenu={false}
          />
          <Column
            header="Acción"
            body={requirementsActionTemplate}
          />
        </DataTable>
      </div>
      {/* Modal for new requirements */}
      <AddRequirementModal
        show={showRequirementModal === "new"}
        onHide={() => {
          if (showRequirementModal !== "new") return;
          setShowRequirementModal("none");
        }}
        requirementTypes={requirementsTypeQuery}
        toastRef={toastRef}
      />
      {/* Modal para editar un requerimiento */}
      <Dialog
        header="Editar requerimiento"
        visible={showRequirementModal === "edit"}
        contentClassName="p-fluid"
        onHide={() => {
          if (showRequirementModal !== "edit") return;
          setShowRequirementModal("none");
          setSelectedRequirement(null);
        }}
        draggable={false}
      >
        <form>
          <div className="p-field">
            <label htmlFor="type">Tipo</label>
            <Dropdown
              id="type"
              name="type"
              options={requirementsTypeQuery.data ?? []}
              optionValue="name"
              optionLabel="name"
              loading={requirementsTypeQuery.isLoading}
              disabled={requirementsTypeQuery.isLoading}
              placeholder="Seleccionar tipo"
              required
              value={selectedRequirement?.type}
            />
          </div>
          <div className="p-field">
            <label htmlFor="name">Nombre</label>
            <InputText
              id="name"
              name="name"
              required
              defaultValue={selectedRequirement?.name}
            />
          </div>
          <Button type="button" label="Guardar cambios" name="editRequirement" />
        </form>
      </Dialog>
      {/* Modal para eliminar requerimiento */}
      <Dialog
        header="Eliminar centro médico"
        visible={showRequirementModal === "delete"}
        style={{ maxWidth: "35vw" }}
        onHide={() => {
          if (showRequirementModal !== "delete") return;
          setShowRequirementModal("none");
          setSelectedRequirement(null);
        }}
        draggable={false}
      >
        <form>
          <p style={{ textAlign: "center" }}>¿Estás seguro de que deseas eliminar el requerimiento <strong>{selectedRequirement?.name}</strong>?</p>
          <div className="delete-confirm-buttons-container">
            <Button type="button" label="Cancelar" style={{ backgroundColor: "#999999", borderColor: "#999999" }} />
            <Button type="button" label="Eliminar" name="deleteMedicalCenter" severity="danger" />
          </div>
        </form>
      </Dialog>
    </Dashboard>
  )
}
