import { DataTable } from "primereact/datatable";
import Dashboard from "../Layout/Dashboard";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { useState } from "react";
import { InputText } from "primereact/inputtext";
import { DeleteButton, EditButton } from "../components/TableButtons";
import { useMutation, useQuery } from "@tanstack/react-query";
import { addMedicalCenter, getMedicalCenters } from "../services/medicalCenters";
import { queryClient } from "../App";
import { FilterMatchMode } from "primereact/api";

/**
 * Listado de centros médicos (CRUD)
 */
export default function MedicalCentersListing() {

  // Modal state
  const [showDialog, setShowDialog] = useState("none");
  const [selectedCenter, setSelectedCenter] = useState(null);

  // Table filters
  const [filters] = useState({
    name: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  // Form status
  const [name, setName] = useState("");

  // Obtener los centros médicos
  const query = useQuery({
    queryKey: ["medicalCenters"],
    queryFn: getMedicalCenters,
  });

  // Crear centro
  const createMutation = useMutation({
    mutationFn: addMedicalCenter,
    onSuccess: () => {
      // Refresh
      queryClient.invalidateQueries({
        queryKey: ["medicalCenters"],
      });
      setShowDialog("none");
    }
  });

  /**
   * Mostrar botones de acción para el centro médico
   */
  function medicalCentersActionTemplate(row) {
    return (
      <div className="d-flex justify-content-center">
        <EditButton onClick={() => { setShowDialog("edit"); setSelectedCenter(row); }} />
        <DeleteButton onClick={() => { setShowDialog("delete"); setSelectedCenter(row); }} />
      </div>
    );
  }

  return (
    <Dashboard headerTitle="Listado de Proveedores">
      <div className="p-fluid">
        <Button
          label="Crear proveedor"
          icon="pi pi-plus"
          className="mb-3"
          style={{ maxWidth: "fit-content" }}
          onClick={() => setShowDialog("new")}
        />
        <DataTable
          value={query.data ?? []}
					stripedRows
					paginator
					alwaysShowPaginator={false}
					rows={15}
					rowsPerPageOptions={[10, 15, 25, 50]}
					dataKey="id"
          filters={filters}
					filterDisplay="row"
					removableSort
          emptyMessage="No se hallaron proveedores."
          loading={query.isLoading}
        >
          <Column
            field="name"
            header="Nombre"
            sortable
            filter
            filterPlaceholder="Buscar proveedor"
            showFilterMenu={false}
          />
          <Column
            header="Acción"
            body={medicalCentersActionTemplate}
          />
        </DataTable>
      </div>
      {/* Modal de creación de proveedor */}
      <Dialog
        header="Crear proveedor"
        visible={showDialog === "new"}
        contentClassName="p-fluid"
        onHide={() => {
          if (showDialog !== "new") return;
          setShowDialog("none");
        }}
        draggable={false}
      >
        <form onSubmit={(e) => {
          e.preventDefault();
          createMutation.mutate(name);
        }}>
          <div className="p-field">
            <label htmlFor="name">Nombre</label>
            <InputText
              id="name"
              name="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <Button type="submit" label="Guardar" />
        </form>
      </Dialog>
      {/* Modal de editar proveedor */}
      <Dialog
        header="Editar proveedor"
        visible={showDialog === "edit"}
        contentClassName="p-fluid"
        onHide={() => {
          if (showDialog !== "edit") return;
          setShowDialog(false);
          setSelectedCenter(null);
        }}
        draggable={false}
      >
        <form onSubmit={(e) => {
          e.preventDefault();

        }}>
          <div className="p-field">
            <label htmlFor="name">Nombre</label>
            <InputText
              id="name"
              name="name"
              defaultValue={selectedCenter?.name}
            />
          </div>
          <Button type="submit" label="Guardar cambios" />
        </form>
      </Dialog>
      {/* Modal de confirmación de eliminar */}
      <Dialog
        header="Eliminar centro médico"
        visible={showDialog === "delete"}
        style={{ maxWidth: "35vw" }}
        onHide={() => {
          if (showDialog !== "delete") return;
          setShowDialog("none");
          setSelectedCenter(null);
        }}
        draggable={false}
      >
        <form onSubmit={(e) => {
          e.preventDefault();
        }}>
          <p style={{ textAlign: "center" }}>¿Estás seguro de que deseas eliminar el centro médico <strong>{selectedCenter?.name}</strong>?</p>
          <div className="delete-confirm-buttons-container">
            <Button type="submit" label="Eliminar" severity="danger" />
            <Button
              type="button"
              label="Cancelar"
              style={{ backgroundColor: "#999999", borderColor: "#999999" }}
              onClick={() => {
                setShowDialog("none");
                setSelectedCenter(null);
              }}
            />
          </div>
        </form>
      </Dialog>
    </Dashboard>
  )
}
