import { useQuery } from "@tanstack/react-query";
import Dashboard from "../Layout/Dashboard";
import { useParams } from "react-router-dom";
import { getCaseById } from "../services/medicalCases";
import { ProgressSpinner } from "primereact/progressspinner";
import FormSubHeading from "../components/FormSubHeading";
import { formatCi, formatDateString, formatFullNumber, readableSex } from "../utils";
import { FileUpload } from "primereact/fileupload";

/**
 * Detalle del caso médico
 */
export default function CaseDetail() {
  // Buscar detalle del caso
  const { caseId } = useParams();
  const { data, isLoading } = useQuery({
    queryKey: ["medicalCase", caseId],
    queryFn: ({ queryKey }) => getCaseById(Number(queryKey[1])),
  });

  return (
    <Dashboard headerTitle="Detalle del caso" backButtonUrl="/casos">
      {isLoading && <ProgressSpinner />}
      {data && !isLoading ? <CaseDetailContent data={data} /> : <p>No se encontró el caso</p>}
    </Dashboard>
  );
}

const CaseDetailContent = ({ data }) => {
  return (
    <div className="p-fluid">
      <h3><i className="pi pi-ticket"></i> Número de caso: {data.id}</h3>
      <FormSubHeading title="Datos del paciente" />
      <div>
        <p><i className="pi pi-id-card"></i> <strong>Cédula:</strong> {formatCi(data.patient_.ci)}</p>
        <p><i className="pi pi-address-book"></i> <strong>Nombre:</strong> {data.patient_.name}</p>
        <p><i className="pi pi-phone"></i> <strong>Teléfono:</strong> {formatFullNumber(data.patient_.phone)}</p>
        <p><i className="pi pi-calendar"></i> <strong>Fecha de Nacimiento:</strong> {formatDateString(data.patient_.birthdate)}</p>
        <p><i className="pi pi-user"></i> <strong>Sexo:</strong> {readableSex(data.patient_.sex)}</p>
      </div>
      <FormSubHeading title="Datos del titular" />
      <div>
        <p><i className="pi pi-id-card"></i> <strong>Cédula:</strong> {formatCi(data.holder_.ci)}</p>
        <p><i className="pi pi-briefcase"></i> <strong>Filial:</strong> {data.holder_.subsidiary}</p>
      </div>
      <FormSubHeading title="Datos del requerimiento" />
      <div>
        <p><i className="pi pi-wave-pulse"></i> <strong>Diagnóstico:</strong> {data.diagnosis_name}</p>
        <p><i className="pi pi-clipboard"></i> <strong>Tipo de Requerimiento:</strong> {data.type_requirement}</p>
        <p><i className="pi pi-file-edit"></i> <strong>Requerimiento:</strong> {data.requirement_name}</p>
        <p><i className="pi pi-building-columns"></i> <strong>Proveedor:</strong> {data.medical_center}</p>
      </div>
      <FormSubHeading title="Cargar soportes médicos" />
      <FileUpload 
        name="medicalFiles[]"
        mode="advanced"
        multiple
        chooseLabel="Elegir archivos"
        uploadOptions={{
          style: { display: "none" }
        }}
        cancelLabel="Cancelar"
        emptyTemplate={<p className="m-0">Cargar informe médico, imagenología, documentación, etc.</p>}
      />
    </div>
  );
}
