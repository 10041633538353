// Constantes

/**
 * API base URL
 */
export const BASE_URL = "https://api.citamed247.com/api/v1/";

/**
 * Genders list for dropdowns
 */
export const GENDERS = [
  { label: "Masculino", value: "M" },
  { label: "Femenino", value: "F" },
];

/**
 * Phone codes for dropdowns
 */
export const PHONE_CODES = [
  { label: "0412", value: "58412" },
  { label: "0414", value: "58414" },
  { label: "0416", value: "58416" },
  { label: "0424", value: "58424" },
  { label: "0426", value: "58426" },
];

/**
 * Months list for dropdowns
 */
export const MONTHS = [
  { label: "Enero", value: 1 },
  { label: "Febrero", value: 2 },
  { label: "Marzo", value: 3 },
  { label: "Abril", value: 4 },
  { label: "Mayo", value: 5 },
  { label: "Junio", value: 6 },
  { label: "Julio", value: 7 },
  { label: "Agosto", value: 8 },
  { label: "Septiembre", value: 9 },
  { label: "Octubre", value: 10 },
  { label: "Noviembre", value: 11 },
  { label: "Diciembre", value: 12 },
];

/**
 * List of user roles for dropdowns
 */
export const USER_ROLES_DROPDOWN = [
  { label: "Conciliador", value: "conciliator" },
  { label: "Auditor", value: "auditor" },
  { label: "Gerente general", value: "manager" },
  { label: "Analista regional", value: "analyst" },
  { label: "Proveedor", value: "medicalCenter" },
];

/**
 * List of day numbers with labels
 */
export const DAYS = [
  { id: 1, shortLabel: "Lun.", fullLabel: "Lunes" },
  { id: 2, shortLabel: "Mar.", fullLabel: "Martes" },
  { id: 3, shortLabel: "Mie.", fullLabel: "Miércoles" },
  { id: 4, shortLabel: "Jue.", fullLabel: "Jueves" },
  { id: 5, shortLabel: "Vie.", fullLabel: "Viernes" },
  { id: 6, shortLabel: "Sab.", fullLabel: "Sábado" },
  { id: 7, shortLabel: "Dom.", fullLabel: "Domingo" },
];
