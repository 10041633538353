import CurrencyInput from "react-currency-input-field";

/**
 * @typedef VSieteCurrencyInputProps
 * @property {string} id
 * @property {string} name
 * @property {string} prefix
 * @property {((value: string | undefined, name?: string, values?: { float: number | null; formatted: string;value: string; }) => void)?} onValueChange
 * @property {boolean | undefined} readOnly
 * @property {number | undefined} value
 */

/**
 * Custom currency input with default properties
 * @param {VSieteCurrencyInputProps} props
 */
export default function VSieteCurrencyInput({ id, name, prefix, onValueChange, readOnly, value }) {
  return (
    <CurrencyInput
      id={id}
      name={name}
      allowNegativeValue={false}
      prefix={prefix}
      decimalsLimit={2}
      decimalScale={2}
      decimalSeparator=","
      groupSeparator="."
      placeholder={`${prefix}0,00`}
      className="p-inputtext"
      onValueChange={onValueChange}
      readOnly={readOnly}
      value={value}
    />
  );
}
