// Servicios para los centros médicos (proveedores)

import { BASE_URL } from "../constants";

/**
 * @typedef MedicalCenter
 * @property {number} id
 * @property {string} name
 * @property {string?} total_advance
 * @property {string?} total_incurred
 * @property {number?} available
 * @property {number?} consumed
 */

/**
 * Get list of medical centers
 */
export async function getMedicalCenters() {
  try {
    const response = await fetch(`${BASE_URL}medical-center/`);
    const data = await response.json();
    
    /** @type MedicalCenter[] */
    const results = data.results;
    return results;
  } catch (error) {
    console.error("Error fetching medical centers", error);
    return [];
  }
}

/**
 * Add medical center
 * @param {string} name
 */
export async function addMedicalCenter(name) {
  try {
    if (!name) {
      return {
        type: "error",
        message: "Name is required",
      };
    }
    const response = await fetch(`${BASE_URL}medical-center/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ name }),
    });
    if (response.status === 201) {
      return {
        type: "success",
        message: "Proveedor registrado con éxito",
      };
    }
    throw new Error(JSON.stringify(response));
  } catch (error) {
    console.error("Error adding medical center", error);
    return {
      type: "error",
      message: "Error registrando proveedor",
    }
  }
}
