import { FileUpload } from "primereact/fileupload";
import FormSubHeading from "../components/FormSubHeading";
import Dashboard from "../Layout/Dashboard";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useQuery } from "@tanstack/react-query";
import { getCases } from "../services/medicalCases";
import { useState } from "react";
import { FilterMatchMode } from "primereact/api";

/**
 * Register a new invoice
 */
export default function CreateInvoice() {

  // Get medical cases
  const medicalCases = useQuery({
    queryKey: ["medicalCases"],
    queryFn: () => getCases(),
  });

  // Table filters
  const [filters] = useState({
    id: { value: null, matchMode: FilterMatchMode.CONTAINS },
    date: { value: null, matchMode: FilterMatchMode.CONTAINS },
    "patient_.name": { value: null, matchMode: FilterMatchMode.CONTAINS },
    requirement_name: { value: null, matchMode: FilterMatchMode.CONTAINS },
    amount: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  return (
    <Dashboard headerTitle="Nueva Factura">
      <div className="p-fluid">
        <div className="pb-3">
          <FormSubHeading title="Cargar archivo" />
          <FileUpload
            mode="basic"
            chooseLabel="Elegir archivo"
          />
        </div>
        <div className="pb-3">
          <FormSubHeading title="Seleccione los casos asociados a la factura" />
          <DataTable
            stripedRows
            removableSort
            filterDisplay="row"
            emptyMessage="No se encontraron casos."
            selectionMode="checkbox"
            loading={medicalCases.isLoading}
            value={medicalCases.data ?? []}
            filters={filters}
          >
            <Column selectionMode="multiple" headerStyle={{ width: "3rem" }} />
            <Column
              field="id"
              header="Nro. de Caso"
              filter
              showFilterMenu={false}
              filterPlaceholder="Buscar caso"
            />
            <Column
              field="date"
              header="Fecha de atención"
              filter
              showFilterMenu={false}
              sortable
              filterPlaceholder="Buscar fecha"
            />
            <Column
              field="patient_.name"
              header="Paciente"
              filter
              showFilterMenu={false}
              filterPlaceholder="Buscar paciente"
            />
            <Column
              field="requirement_name"
              header="Requerimiento"
              filter
              showFilterMenu={false}
              filterPlaceholder="Buscar requerimiento"
            />
            <Column
              field="amount"
              header="Monto"
              filter
              showFilterMenu={false}
              filterPlaceholder="Buscar monto"
            />
          </DataTable>
        </div>
      </div>
    </Dashboard>
  );
}
