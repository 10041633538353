// Funciones de utilidad general

/**
 * Devuelve un valor monetario formateado: 100000 -> 100.000,00
 * @param {number | string} value 
 */
export function currencyFormat(value) {
  return Number(value).toLocaleString('es', {
    minimumFractionDigits: 2,
  });
}

/**
 * Devuelve el valor legible del sexo (Masculino o Femenino)
 * @param {"M" | "F"} sex 
 */
export function readableSex(sex) {
  if (sex === "M") {
    return "Masculino";
  }
  return "Femenino";
}

/**
 * Devuelve la fecha en formato yyyy/mm/dd
 * @param {Date} date 
 */
export function formatDate(date) {
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const year = date.getFullYear();
  return `${year}-${month}-${day}`;
}

/**
 * Returns a date from yyyy-mm-dd to dd/mm/yyyy
 * @param {string} date 
 */
export function formatDateString(date) {
  const [year, month, day] = date.split("-");
  return `${day}/${month}/${year}`;
}

/**
 * Devuelve el nombre del banco a partir de su ID
 * @param {number | string} id 
 * @param {Array | undefined} banks 
 */
export function getBankNameFromId(id, banks) {
  const bank = banks?.find((bank) => bank.id === id);
  return bank?.name ?? "";
}

/**
 * Devuelve una cédula formateada: V12345678 -> V-12345678
 * @param {string} ci 
 */
export function formatCi(ci) {
  return ci.replace(/^([VE])-?/, "$1-");
}

/**
 * Returns phone number formatted: 584243001234 -> 0424-3001234
 * @param {string} phoneNumber 
 */
export function formatFullNumber(phoneNumber) {
  return phoneNumber.replace(/^58/, "0").replace(/(\d{4})(\d{7})/, "$1-$2");
}
