// API functions for advances

import { BASE_URL } from "../constants";

/**
 * @typedef Advance
 * @property {number} medical_center
 * @property {number} amount_dolar
 * @property {number} rate
 * @property {number} amount_bs
 * @property {string} date
 * @property {string} reference_payment
 * @property {string?} suport_payment
 * @property {number} bank_emitter
 * @property {number} bank_receiver
 */

/**
 * Add a new advance
 * @param {Advance} body
 */
export async function createAdvance(body) {
  try {
    const response = await fetch(BASE_URL + "advance/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
    if (response.status === 201) {
      return { type: "success", message: "Anticipo guardado con éxito" };
    }
    throw new Error(JSON.stringify(response));
  } catch (error) {
    console.error("Error creating advance: ", error);
    return { type: "error", message: "Error creando anticipo" };
  }
}

/**
 * Returns the list of advances from a specific medical center
 * @param {number | string} id Medical center ID
 */
export async function getAdvancesFromMedicalCenter(id) {
  try {
    const response = await fetch(BASE_URL + "advance/?medical_center=" + id);
    if (response.status === 200) {
      const data = await response.json();
      return data.results;
    }
    throw new Error(JSON.stringify(response));
  } catch (error) {
    console.error("Error getting advances: ", error);
    return [];
  }
}
