import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { ListBox } from "primereact/listbox";
import { OverlayPanel } from "primereact/overlaypanel";
import { useState } from "react";

/**
 * Text input that shows selectable suggestions in an overlay panel
 * @param {object} props
 * @param {string} props.inputName Name and id of the text input
 * @param {string} props.inputValue Value of the text input
 * @param {boolean} props.inputDisabled Whether the text input is disabled
 * @param {(e: React.ChangeEvent) => void} props.onTextChange Function to call when the text input value changes
 * @param {object} props.query Query object from useQuery
 * @param {string} props.notFoundBtnLabel Label for the button to create a item when no results are found
 * @param {() => void} props.notFoundBtnClick Function to call when the button to create a item is clicked
 * @param {(item: any) => JSX.Element} props.itemTemplate Function to render each item in the list
 * @param {React.MutableRefObject<OverlayPanel>} props.overlayRef Ref to the overlay panel
 * @param {(e: React.KeyboardEvent) => void} props.onSearchEnter Function to call when the enter key is pressed on text input
 */
export default function InputWithSuggestions({
  inputName,
  inputValue,
  inputDisabled,
  onTextChange,
  query,
  notFoundBtnLabel,
  notFoundBtnClick,
  itemTemplate,
  overlayRef,
  onSearchEnter,
}) {

  const [selected, setSelected] = useState(null);

  return (
    <>
      <InputText
        id={inputName}
        name={inputName}
        value={inputValue}
        onChange={(e) => {
          onTextChange(e);
          if (e.target.value.length >= 3 && !overlayRef.current.isVisible()) {
            overlayRef.current.toggle(e);
          }
        }}
        onKeyUp={onSearchEnter}
        disabled={inputDisabled}
        autoComplete="off"
      />
      <OverlayPanel ref={overlayRef}>
        {query.isLoading || query.isPending ?
          <p>Cargando...</p>
          : query.data?.length === 0 ? (
            <div className="flex align-items-center">
              <p>No se hallaron resultados.</p>
              <Button label={notFoundBtnLabel} link type="button" onClick={notFoundBtnClick} />
            </div>
          ) : (
              <ListBox
                value={selected}
                onChange={(e) => setSelected(e.value)}
                options={query.data ?? []}
                itemTemplate={itemTemplate}
              />
            )
        }
      </OverlayPanel>
    </>
  )
}
