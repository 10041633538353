import { DataTable } from "primereact/datatable";
import Dashboard from "../Layout/Dashboard";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { useState } from "react";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { USER_ROLES_DROPDOWN } from "../constants";

/**
 * User table and management page
 */
export default function Users() {

  // Dialog visibility
  const [showDialog, setShowDialog] = useState(false);

  return (
    <Dashboard headerTitle="Gestionar usuarios">
      <div className="p-fluid">
        <Button
          icon="pi pi-plus"
          label="Crear usuario"
          className="mb-3"
          type="button"
          style={{ maxWidth: "fit-content" }}
          onClick={() => setShowDialog(true)}
        />
        <DataTable emptyMessage="Sin usuarios registrados.">
          <Column field="email" header="Correo electrónico" />
          <Column field="role" header="Rol" />
        </DataTable>
      </div>
      <NewUserDialog
        show={showDialog}
        onHide={() => { if (showDialog) setShowDialog(false); }}
      />
    </Dashboard>
  );
}

function NewUserDialog({ show, onHide }) {

  // Form state
  const [email, setEmail] = useState("");
  const [role, setRole] = useState(null);

  const canSubmit = email.length > 0 && role !== null;

  return (
    <Dialog header="Nuevo usuario" visible={show} onHide={onHide} draggable={false}>
      <form className="p-fluid">
        <div className="p-field">
          <label htmlFor="email">Correo electrónico</label>
          <InputText id="email" name="email" required type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
        </div>
        <div className="p-field">
          <label htmlFor="role">Rol</label>
          <Dropdown
            inputId="role"
            name="role"
            required
            placeholder="Seleccione un rol"
            value={role}
            onChange={(e) => setRole(e.value)}
            options={USER_ROLES_DROPDOWN}
          />
        </div>
        <Button label="Crear" disabled={!canSubmit} />
      </form>
    </Dialog>
  );
}
