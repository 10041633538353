import { Card } from "primereact/card";
import TemplateDemo from "../TopBar/TopBar";
import { InputText } from "primereact/inputtext";
import "./Login.css";
import { Button } from "primereact/button";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

/**
 * User login form
 */
export default function Login() {

  // Form state
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  //const canSubmit = username.length > 0 && password.length > 0;

  const navigate = useNavigate();

  return (
    <>
      <TemplateDemo />
      <div className="login-container">
        <Card title="Iniciar sesión">
          <form className="p-fluid">
            <div className="p-field">
              <label htmlFor="email">Correo</label>
              <InputText id="email" name="email" type="email" value={username} onChange={(e) => setUsername(e.target.value)} />
            </div>
            <div className="p-field">
              <label htmlFor="password">Contraseña</label>
              <InputText id="password" name="password" type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
            </div>
            <Button label="Ingresar" onClick={() => navigate("/casos")} />
          </form>
        </Card>
      </div>
    </>
  );
}
