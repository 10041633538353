import { useParams } from "react-router-dom";
import Dashboard from "../Layout/Dashboard";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useState } from "react";
import { Dialog } from "primereact/dialog";
import "./Advances.css";
import { currencyFormat, getBankNameFromId } from "../utils";
import { FileUpload } from "primereact/fileupload";
import { Button } from "primereact/button";
import { Tag } from "primereact/tag";
import { Dropdown } from "primereact/dropdown";
import { useQuery } from "@tanstack/react-query";
import { getAdvancesFromMedicalCenter } from "../services/advances";
import { getBanks } from "../services/banks";
import { FilterMatchMode, FilterService } from "primereact/api";

/**
 * Applies currency format to the given number
 * @param {typeof items[number]} row
 */
function amountDollarsTemplate(row) {
  return currencyFormat(row.amount_dolar);
}

/**
 * Applies tag format to filter dropdown
 * @param {"Cargado" | "Pendiente"} option 
 */
function invoiceFilterTemplate(option) {
  const severity = option === "Cargado" ? "success" : "danger";
  return <Tag severity={severity} value={option} />;
}

// Filter for invoice status
FilterService.register("custom_suport_payment", (value, filter) => {
  if (filter === "Cargado") {
    return value !== null;
  }
  return !value;
});

const invoiceStatusFilterMenu = (options) => (
  <Dropdown
    value={options.value}
    options={["Cargado", "Pendiente"]}
    onChange={(e) => options.filterApplyCallback(e.value)}
    itemTemplate={invoiceFilterTemplate}
    placeholder="Seleccionar status"
    className="p-column-filter"
    showClear
  />
);

/**
 * Listado de Anticipos del Centro Médico
 */
export default function MedicalCenterAdvances() {

  // Dialog state
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [isFileSelected, setIsFileSelected] = useState(false);
  const shouldRenderInvoiceInput = selectedInvoice && !selectedInvoice.suport_payment;

  // Table filters
  const [filters] = useState({
    reference_payment: { value: null, matchMode: FilterMatchMode.CONTAINS },
    suport_payment: { value: null, matchMode: FilterMatchMode.CUSTOM },
  });

  // Get advances from medical center
  const params = useParams();
  const advancesQuery = useQuery({
    queryKey: ["advances", params.centerId],
    queryFn: () => getAdvancesFromMedicalCenter(params.centerId),
  });

  // Get banks
  const banksQuery = useQuery({
    queryKey: ["banks"],
    queryFn: () => getBanks(),
  });

  const bankReceiverTemplate = (row) => {
    return getBankNameFromId(row.bank_receiver, banksQuery.data);
  }

  /**
   * Applies tag format to invoice
   * @param {typeof items[number]} row
   */
  function invoiceTemplate(row) {
    if (row.suport_payment) {
      return (
        <Button icon="pi pi-check" label="Ver Detalles" severity="success" onClick={() => setSelectedInvoice(row)} />
      );
    }
    return <Button icon="pi pi-upload" label="Cargar Archivo" onClick={() => setSelectedInvoice(row)} />;
  }

  return (
    <Dashboard headerTitle="Anticipos" backButtonUrl="/anticipos">
      <h1 style={{ fontSize: "1.5rem" }}>
        Anticipos del centro médico
      </h1>
      <DataTable
        value={advancesQuery.data ?? []}
        style={{ minWidth: "50rem" }}
        stripedRows
        selectionMode="single"
        onRowSelect={(event) => setSelectedInvoice(event.data)}
        filterDisplay="row"
        removableSort
        loading={advancesQuery.isLoading || banksQuery.isLoading}
        emptyMessage="No se encontraron anticipos."
        filters={filters}
      >
        <Column
          field="date"
          header="Fecha"
          sortable
        />
        <Column
          field="amount_dolar"
          header="Monto ($)"
          body={amountDollarsTemplate}
          sortable
        />
        <Column
          field="bank_receiver"
          header="Banco Beneficiario"
          body={bankReceiverTemplate}
        />
        <Column
          field="reference_payment"
          header="Nro. Referencia"
          filter
          filterPlaceholder="Buscar por referencia"
          showFilterMenu={false}
        />
        <Column
          field="suport_payment"
          header="Soporte de Pago"
          body={invoiceTemplate}
          filter
          filterElement={invoiceStatusFilterMenu}
          showFilterMenu={false}
        />
      </DataTable>
      <Dialog
        header="Detalle del anticipo"
        visible={selectedInvoice !== null}
        className="advance-modal"
        contentClassName="advance-modal-content"
        onHide={() => {
          if (!selectedInvoice) return;
          setSelectedInvoice(null);
        }}
        draggable={false}
      >
        <section>
          <p><i className="pi pi-calendar" aria-hidden="true"></i> <strong>Fecha: </strong>{selectedInvoice?.date}</p>
          <p><i className="pi pi-dollar" aria-hidden="true"></i> <strong>Monto ($): </strong>{currencyFormat(selectedInvoice?.amount_dolar)}</p>
          <p><i className="pi pi-dollar" aria-hidden="true"></i> <strong>Monto (Bs.): </strong>{currencyFormat(selectedInvoice?.amount_bs)}</p>
          <p><i className="pi pi-arrow-right-arrow-left" aria-hidden="true"></i> <strong>Tasa: </strong>{currencyFormat(selectedInvoice?.rate)} Bs.</p>
        </section>
        <section>
          <p><i className="pi pi-hashtag" aria-hidden="true"></i> <strong>Nro. de referencia: </strong>{selectedInvoice?.reference_payment}</p>
          <p><i className="pi pi-building-columns" aria-hidden="true"></i> <strong>Banco emisor: </strong>{getBankNameFromId(selectedInvoice?.bank_emitter, banksQuery.data)}</p>
          <p><i className="pi pi-building-columns" aria-hidden="true"></i> <strong>Banco beneficiario: </strong>{getBankNameFromId(selectedInvoice?.bank_receiver, banksQuery.data)}</p>
        </section>
        <section>
          <p><i className="pi pi-receipt" aria-hidden="true"></i> <strong>Soporte:</strong></p>
          {shouldRenderInvoiceInput ?
            <form>
              <FileUpload
                name="invoice"
                chooseLabel="Cargar soporte"
                cancelLabel="Cancelar"
                uploadOptions={{
                  style: {
                    display: "none",
                  },
                }}
                onSelect={() => setIsFileSelected(true)}
              />
                {isFileSelected && <Button id="saveInvoice" severity="success" icon="pi pi-save" label="Guardar soporte" />}
            </form>
            : <p>Soporte cargado (Mostrar vista previa)</p>
          }
        </section>
      </Dialog>
    </Dashboard>
  );
}
